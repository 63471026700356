// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-de-js": () => import("./../../../src/pages/contact.de.js" /* webpackChunkName: "component---src-pages-contact-de-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-privacy-policy-de-js": () => import("./../../../src/pages/privacy-policy.de.js" /* webpackChunkName: "component---src-pages-privacy-policy-de-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-services-en-js": () => import("./../../../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-strategy-call-de-js": () => import("./../../../src/pages/strategy-call.de.js" /* webpackChunkName: "component---src-pages-strategy-call-de-js" */),
  "component---src-pages-strategy-call-en-js": () => import("./../../../src/pages/strategy-call.en.js" /* webpackChunkName: "component---src-pages-strategy-call-en-js" */),
  "component---src-pages-work-en-js": () => import("./../../../src/pages/work.en.js" /* webpackChunkName: "component---src-pages-work-en-js" */),
  "component---src-templates-blog-index-index-js": () => import("./../../../src/templates/blogIndex/index.js" /* webpackChunkName: "component---src-templates-blog-index-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/BlogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-work-index-js": () => import("./../../../src/templates/Work/index.js" /* webpackChunkName: "component---src-templates-work-index-js" */)
}

